export const proyectos = [
  {
    title: "BnBuddy",
    techs: 'JavaScript Boostrap CSS',
    description: 'Guías digitales para alojamientos',
    site:'https://bnbuddy.netlify.app/',
    img:'https://res.cloudinary.com/derznxjam/image/upload/f_auto,q_auto/v1/portfolio/dwgxcy18towbvyxo1vj0',
    id:'bnbuddy'
  },
  {
    title: "Naval Electric",
    techs: 'JavaScript SASS CSS HTML',
    description: 'Sitio web realizado para Naval Electric, una empresa que realiza instalaciones eléctricas en barcos',
    site:'https://naval-electric.com.ar/',
    img:'https://res.cloudinary.com/derznxjam/image/upload/v1699486019/portfolio/naval-electric_zfzuud.jpg',
    id:'navalElectric'
  },
  {
    title:"Introducción a Flexbox",
    techs:'JavaScript bootstrap CSS HTML',
    description:"Sitio web realizado a modo de tutorial para aprender lo basico de Flexbox",
    site:'https://introduccion-a-flexbox.netlify.app/',
    img:'https://res.cloudinary.com/derznxjam/image/upload/v1699486019/portfolio/int-a-flexbox_q6bepg.jpg',
    id:'flexbox'
  },
  {
    title: "Cryptojuegos",
    techs: 'JavaScript SASS CSS HTML',
    description: 'Sitio web sobre juegos de temática cripto para ganar dinero',
    site:'https://cryptojuegos.netlify.app/',
    img:'https://res.cloudinary.com/derznxjam/image/upload/v1699489254/portfolio/cryptojuegos_pymwwl.jpg',
    id:'cryptojuegos'
  },
];


export const proyectosLimpios =
{
  bolucompras:false,
  navalElectric:false,
  flexbox:false,
  cryptojuegos:false,
}
