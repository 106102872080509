export const icons = {
    react_logo : 'https://res.cloudinary.com/derznxjam/image/upload/v1698611001/portfolio/react_w_a0hclm.png',
    javascript : 'https://res.cloudinary.com/derznxjam/image/upload/v1698611017/portfolio/javascript_w_mibp9n.png',
    nextjs : 'https://res.cloudinary.com/derznxjam/image/upload/v1698611015/portfolio/nextjs_w_s2hrhn.png',
    node : 'https://res.cloudinary.com/derznxjam/image/upload/v1698611022/portfolio/nodejs_w_ahjcyp.png',
    cpp : 'https://res.cloudinary.com/derznxjam/image/upload/v1698611004/portfolio/cpp_w_mqqz6j.png',
    mongodb : 'https://res.cloudinary.com/derznxjam/image/upload/v1698610999/portfolio/mongodb_w_hkbhei.png',
    npm : 'https://res.cloudinary.com/derznxjam/image/upload/v1698611020/portfolio/npm_w_jgogm0.png',
    tailwind : 'https://res.cloudinary.com/derznxjam/image/upload/v1698611002/portfolio/tailwind_w_iwucxh.png',
    sass : 'https://res.cloudinary.com/derznxjam/image/upload/v1698611001/portfolio/sass_w_ycvrad.png',
    socket_io : 'https://res.cloudinary.com/derznxjam/image/upload/v1698611012/portfolio/socket-io_w_a02cnz.png',
    git : 'https://res.cloudinary.com/derznxjam/image/upload/v1698611021/portfolio/git_w_ktfqsa.png',
    github:'https://res.cloudinary.com/derznxjam/image/upload/v1698611011/portfolio/github_w_pwfk8t.png',
    css3 : 'https://res.cloudinary.com/derznxjam/image/upload/v1698611005/portfolio/css3_w_gfgcah.png',
    bootstrap : 'https://res.cloudinary.com/derznxjam/image/upload/v1698610999/portfolio/bootstrap_w_ladgnl.png',
    firebase : 'https://res.cloudinary.com/derznxjam/image/upload/v1698611018/portfolio/firebase_w_cguhw0.png',
    redux:'https://res.cloudinary.com/derznxjam/image/upload/v1698611023/portfolio/redux_w_yu0abp.png',
    perfil : 'https://res.cloudinary.com/derznxjam/image/upload/f_auto,q_auto/v1/portfolio/kcrqc5qi1rq9zq1ys03i',
    retuit : 'https://res.cloudinary.com/derznxjam/image/upload/v1698611029/portfolio/retuit_w_zco08p.png',
    user : 'https://res.cloudinary.com/derznxjam/image/upload/v1698611003/portfolio/user_w_r9cgat.png',
    image: 'https://res.cloudinary.com/derznxjam/image/upload/v1698611000/portfolio/photo_w_fixzu1.png',
    gif: 'https://res.cloudinary.com/derznxjam/image/upload/v1698611022/portfolio/gif_w_ndkmsj.png',
    emoji: 'https://res.cloudinary.com/derznxjam/image/upload/v1698611018/portfolio/emoji_w_ylsizz.png',
    pin: 'https://res.cloudinary.com/derznxjam/image/upload/v1698611020/portfolio/map-pin_w_cntzge.png',
    email:'https://res.cloudinary.com/derznxjam/image/upload/v1698611027/portfolio/mail_w_ja5sy4.png',
    gmail:'https://res.cloudinary.com/derznxjam/image/upload/v1698611027/portfolio/gmail_w_latkos.png',
    home:'https://res.cloudinary.com/derznxjam/image/upload/v1698611019/portfolio/house_w_tybwty.png',
    search:'https://res.cloudinary.com/derznxjam/image/upload/v1698611010/portfolio/search_w_mqfjxw.png',
    search_b:'https://res.cloudinary.com/derznxjam/image/upload/v1698611025/portfolio/search_cgoxp3.png',
    message:'https://res.cloudinary.com/derznxjam/image/upload/v1698611008/portfolio/message_w_hs5g2p.png',
    heart:'https://res.cloudinary.com/derznxjam/image/upload/v1698611007/portfolio/heart_w_rbkbew.png',
    dots_vertical:'https://res.cloudinary.com/derznxjam/image/upload/v1698611011/portfolio/dots-vertical_w_rcu4fl.png',
    edit:'https://res.cloudinary.com/derznxjam/image/upload/v1698611010/portfolio/edit_w_ru3afe.png',
    linkedin:'https://res.cloudinary.com/derznxjam/image/upload/v1698610999/portfolio/linkedin_w_gywz5q.png',
    share:'https://res.cloudinary.com/derznxjam/image/upload/v1698611020/portfolio/share_w_uyl7qg.png',
    heartbrake:'https://res.cloudinary.com/derznxjam/image/upload/f_auto,q_auto/v1/portfolio/ao4hpys8hi5km1rqryue',
    heartBlack:'https://res.cloudinary.com/derznxjam/image/upload/f_auto,q_auto/v1/portfolio/ry9nbidgoptrr0bw0uid',
    follow:'https://res.cloudinary.com/derznxjam/image/upload/f_auto,q_auto/v1/portfolio/xethedkbqruqxuvjgzf4',
    unfollow:'https://res.cloudinary.com/derznxjam/image/upload/f_auto,q_auto/v1/portfolio/akwexacqv7vampr3u7wy',
    heartWhite:'https://res.cloudinary.com/derznxjam/image/upload/f_auto,q_auto/v1/portfolio/ovuho15qvkhlr4a64jpf',
    bookmark:'https://res.cloudinary.com/derznxjam/image/upload/f_auto,q_auto/v1/portfolio/e5ejrza58rm8mwpfav6g',
    bookmarkWhite:'https://res.cloudinary.com/derznxjam/image/upload/f_auto,q_auto/v1/portfolio/twllrk84amf4f6snuswr',
    google:'https://res.cloudinary.com/derznxjam/image/upload/f_auto,q_auto/v1/portfolio/obljvkodarhe63e366m0'
}

export const skills = 
    {
      react_logo : false,
      javascript : false,
      nextjs : false,
      node : false,
      cpp : false,
      mongodb : false,
      git : false,
      firebase : false,
      tailwind : false,
      npm : false,
      sass : false,
      socket_io : false,
      css3 : false,
      bootstrap : false,
    }